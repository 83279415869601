.cardFan_cards_card {
  @apply absolute bg-black;
  width: 40%;
  box-shadow: 5px 6px 22px #000;

  @screen lg {
    width: 18%;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(6),
  &:nth-child(7) {
    display: none;
    @screen lg {
      display: block;
    }
  }

  &:nth-child(1) {
    left: 12%;
    transform: translateX(-12%) rotate(-30deg);
    z-index: 1;
    top: 30%;
  }
  &:nth-child(2) {
    left: 24%;
    transform: translateX(-24%) rotate(-15deg);
    z-index: 2;
    top: 15%;
  }
  &:nth-child(3) {
    left: 10%;
    transform: translateX(-6%) rotate(-10deg);
    z-index: 3;
    top: 5%;

    @screen lg {
      left: 38%;
      transform: translateX(-38%) rotate(-5deg);
    }
  }
  &:nth-child(4) {
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
  }
  &:nth-child(5) {
    left: 88%;
    transform: translateX(-89%) rotate(10deg);
    z-index: 3;
    top: 5%;
    @screen lg {
      left: 62%;
      transform: translateX(-62%) rotate(5deg);
    }
  }
  &:nth-child(6) {
    left: 76%;
    transform: translateX(-76%) rotate(15deg);
    z-index: 2;
    top: 15%;
  }
  &:nth-child(7) {
    left: 88%;
    transform: translateX(-88%) rotate(30deg);
    z-index: 1;
    top: 30%;
  }
}
